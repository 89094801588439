<template>
  <div>
    <b-overlay :show="false" rounded="sm">
    <b-card>
      <b-form class="mt-2" ref="formBudget">       
        <input type="hidden" name="work_order_id" v-model="order_id">
        <!-- form -->
        <b-row>
          <!-- <b-col sm="12">
            <b-form-group label="TITULO DE PRESPUESTO" label-for="description">
              <b-form-textarea name="description" required />
            </b-form-group>
          </b-col> -->
          <b-col sm="12">
            <b-form-group label="Fecha Presupuesto" label-for="budget_date">
              <b-form-input type="date" v-model="budget_date" name="budget_date"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" class="mt-2 mr-1" type="button" @click="saveForm">
              Crear Presupuesto
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    </b-overlay>
  </div>
</template>

<script>
  import { BFormTextarea, BButton, BForm, BOverlay,BCardHeader, BCardBody, BFormGroup, BFormInput, BRow, BCol,  BCard,BCardText} from 'bootstrap-vue'  
  export default {
    components : { BFormTextarea, BButton, BForm, BOverlay,BCardHeader, BCardBody, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText,},
    data() {
      return {
        order_id :  this.$route.params.order,
        budget_date : new Date().toISOString().slice(0,10) 
      }
    },
    created () {

    },
    methods: {
      saveForm : async function (e) {
        this.show = true;
        var formData = new FormData(this.$refs.formBudget);
        await this.$http({
          method: "post",
          url: "/api/insertBudget",
          data: formData,
        }).then(response => { 
          let id = response.data
          this.show = false;
          this.$router.push("/budget/" + id);
        })
          .catch(response => { window.console.log(response) });
      }
    }
  }
</script>

<style>

</style>